
<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 ">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
            <div class="row">
              <div class="col-lg-6">
              <h6 class="text-white text-capitalize ps-3">العملاء</h6>
              </div>
               <div class="col-lg-6">
                  <!-- <router-link to="/students/change">

               <span class="badge badge-sm new-student"><i class="fas fa-exchange-alt"></i> تغيير الفرقة </span>
                 </router-link> -->
                 <router-link to="/clients/create">

               <span class="badge badge-sm new-student"><i class="fas fa-location-arrow"></i>إضافة عميل </span>
                 </router-link>

               </div>
              </div>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <!-- filters -->
            <div class="row align-center ex-row">

            </div>
          <div class="row">
            <vdtnet-table style="direction:rtl" class=" table-striped table  table-bordered2 text-center "
            :jquery="jq"
            ref="table"
            :fields="fields"
            :opts="options"
            @show="doAlertShow"
            @showReport = "doAlertShowReport"
            @edit="doAlertEdit"
            @reverseStatus='toggleStatus'
            @status='toggleStatus'
            @register="doAlertRegister"
            @reserve='doAlertReserve'
            @exportExcel="doAlertExcel"
            @delete="doAlertDelete"
            @reloaded="doAfterReload"
            @table-creating="doCreating"
            @table-created="doCreated"
          >
          </vdtnet-table>
          </div>
          </div>
        </div>
      </div>
    </div>
    <!-- modal -->
  </div>
</template>

<script>
import VdtnetTable from 'vue-datatables-net'
import $ from 'jquery'
import swal from 'sweetalert'
export default {
  name: 'AllStudents',
  components: {
    VdtnetTable

  },
  data () {
    return {
      pagination: {
        rowsPerPage: 30
      },
      jq: $,
      options: {
        ajax: {
          beforeSend: function (xhr) {
            xhr.setRequestHeader(
              'Authorization',
              'Bearer ' + localStorage.token
            )
          },
          url: `${this.$hostUrl}clients`,
          error: function (xhr, errorType, error) {
            // Display the default error message popup
            alert('An error occurred while fetching data from the server.')
            const currentTime = new Date().toLocaleString()
            const errorData = {
              response: xhr.responseText,
              time: currentTime,
              request: {
                url: this.url
              }
            }
            localStorage.setItem('dataTableError', JSON.stringify(errorData))
          }
        },
        buttons: [
          {
            extend: 'print',
            text: '',
            titleAttr: 'طباعة',
            className: 'fas fa-print btn-primary',
            exportOptions: {
              columns: [0, 1, 2, 3, 4, 5]
            },
            customize: function (win) {
              $(win.document.body).css('direction', 'rtl')
            }
          },
          {
            extend: 'copy',
            text: '',
            className: 'fas fa-copy btn-warning',
            titleAttr: 'نسخ',
            exportOptions: {
              columns: 'th:not(:last-child)'
            }
          },
          {
            extend: 'excel',
            text: '',
            titleAttr: 'إكسل',
            className: 'fas fa-table btn-success',
            exportOptions: {
              columns: 'th:not(:last-child)'
            }
          }
        ],
        dom:
          "<'row'<'col-sm-12 col-md-6'f><'col-sm-12 col-md-6 added'l>>" +
          "<'row'<'col-sm-12'Btr>>" +
          "<'row'<'col-sm-12 col-md-6'p><'col-sm-12 col-md-6'i>>",
        paging: true,
        language: {
          url: 'https://cdn.datatables.net/plug-ins/1.10.24/i18n/Arabic.json',
          processing: '<i class="fa fa-spinner fa-spin" style="font-size:40px;color:#7aeb7f"></i>'
        },
        responsive: false,
        processing: true,
        searching: true,
        searchDelay: 1500,
        destroy: true,
        ordering: true,
        lengthChange: true,
        serverSide: true,
        order: [[5, 'desc']],
        fixedHeader: true,
        saveState: true,
        select: false
      },
      fields: {
        sequence: {
          label: 'رقم-التسلسل ',
          sortable: true,
          searchable: true
        },
        name: {
          label: ' اسم العميل ',
          sortable: true,
          searchable: true
        },
        phone: {
          label: ' الهاتف ',
          sortable: true,
          searchable: true
        },
        'event.name': {
          label: ' الحفلة ',
          sortable: true,
          searchable: true
        },

        created_at: {
          label: 'تاريخ الإنشاء',
          sortable: true,
          searchable: true
        },
        actions: {
          isLocal: true,
          label: 'العمليات',
          defaultContent:
            '&ensp;<a href="javascript:void(0);" data-action="edit"><i class="fas fa-edit" title="تعديل"></i> </a>' +
             '&ensp;<a href="javascript:void(0);" data-action="show"><i class="fas fa-eye" title="عرض"></i> </a>'
        }
      },
      errors: []
    }
  },
  methods: {
    toggleStatus (data, row, tr) {
      swal({
        title: 'هل انت متأكد ؟',
        text: '  بمحرد الموافقة سيتم تغيير حالة الحفلة!',
        icon: 'warning',
        buttons: ['لا', 'نعم'],
        dangerMode: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33'
      }).then((willDelete) => {
        if (willDelete) {
          const formData = new FormData()
          formData.append('_method', 'PUT')
          this.errors = this.postRequest(
            formData,
            this.$hostUrl + 'events/' + data.id + '/toggle-status',
            ''
          )
          tr.remove()
          const table = this.$refs.table
          setTimeout(() => {
            table.reload()
          }, 1500)
        } else {
          swal({
            title: 'تم الإلغاء',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: ' تم'
          })
        }
      })
    },
    changProcessingTxt () {
      console.log($('.dataTables_processing'))
    },
    doAlertShow (data) {
      this.$router.push({
        path: `/clients/${data.id}/show`,
        params: { id: data.id }
      })
    },
    doAlertEdit (data) {
      this.$router.push({
        path: `/clients/${data.id}/edit`,
        params: { id: data.id }
      })
    },
    // filters

    async doAlertExcel (data) {
      this.errors = await this.$http.get(this.$hostUrl + 'events/' + data.id + '/export/images', '').then((response) => {
        if (!response.data.error) {
          const downloadUrl = response.data.data.download_url

          // Create a hidden <a> element
          const link = document.createElement('a')
          link.style.display = 'none'
          link.href = downloadUrl

          // Append the <a> element to the DOM
          document.body.appendChild(link)

          // Programmatically click on the <a> element
          link.click()

          // Clean up by removing the <a> element from the DOM
          document.body.removeChild(link)
        }
      }).catch(function (error) {
        if (Object.prototype.hasOwnProperty.call(error, 'response')) {
          swal({ title: error.response.data.message, buttons: 'تم' })
        }
      })
    },

    updateData () {
      const table = this.$refs.table
      table.reload()
    },
    // eslint-disable-next-line no-unused-vars
    doAfterReload (data, table) {
      console.log('data reloaded')
    },
    // eslint-disable-next-line no-unused-vars
    doCreating (comp, el) {

    },
    // eslint-disable-next-line no-unused-vars
    doCreated (comp) {
      console.log('created', this.$hostUrl)
    },
    doSearch () {
      this.$refs.table.search(this.quickSearch)
    },
    doExport (type) {
      const parms = this.$refs.table.getServerParams()
      parms.export = type
    },
    formatCode (zipcode) {
      return zipcode.split('-')[0]
    }
  }
}
</script>
<style >
.ps-3 {
    padding-right: 1rem !important;}
    .me-3 {
    margin-left: 1rem !important;}
      .fa-edit{
      padding-left: 12px;
    }
    .fa-eye{
      font-size:17px
    }
    /* .table-bordered2 > :not(caption) > * > *{
    border: 1px solid red !important
} */
/* .table-bordered2 tr{
  border-width: unset !important
} */
/* .table-bordered2 .table{
    border: 1px solid red !important
} */
.all-students #vdtnetable1{
  border: 1px solid #e0dadd !important;
      border-collapse: separate;
    border-spacing: 1px;
    background: #e0dadd !important;
}
.all-students table{
  border: 1px solid #e0dadd !important;
      border-collapse: separate;
    border-spacing: 1px;
    background: #e0dadd !important;
}

.all-students table.dataTable{
 border: 1px solid #e0dadd !important;
      border-collapse: separate;
    border-spacing: 1px;
    background: #e0dadd !important;;
}
    /* table{
      border-spacing: unset !important;
      border-width:unset !important;
      border-color:#bcb9b9 !important;
      border-bottom-color:#222 !important
    }
   .table-bordered2 {
      border-width:unset !important;
    }
    .bottom{
      margin-bottom: 24px !important;
    }
    .w-75{
      width:75% !important
    }*/
    .course-modal{
    width: 550px;
    top: 30% !important;
    right: 30% !important;
    }
    .switch input{
    width: 30px !important;
    height: 25px !important;
    cursor: pointer
    }
</style>
